// /** SCSS DARK THEME PRIMARY COLORS */
// $primary-100: #776eff;
// $primary-200: #8a7dff;
// $primary-300: #9b8dff;
// $primary-400: #ab9cff;
// $primary-500: #baacff;
// $primary-600: #c9bcff;
// /** SCSS DARK THEME SURFACE COLORS */
// $surface-100: #242326;
// $surface-200: #38383a;
// $surface-300: #4e4d50;
// $surface-400: #656467;
// $surface-500: #7d7c7e;
// $surface-600: #959597;
// /** SCSS DARK THEME MIXED SURFACE COLORS */
// $surface-mixed-100: #2d2a39;
// $surface-mixed-200: #413e4c;
// $surface-mixed-300: #565360;
// $surface-mixed-400: #6c6a75;
// $surface-mixed-500: #83818b;
// $surface-mixed-600: #9b99a1;

// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #4b9149; $primary-200:  #609d5c; $primary-300:  #74a970; $primary-400:  #88b583; $primary-500:  #9cc197; $primary-600:  #afceab;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #2d372d; $surface-mixed-200:  #414a41; $surface-mixed-300:  #565f56; $surface-mixed-400:  #6c746c; $surface-mixed-500:  #838a83; $surface-mixed-600:  #9ba09a;
               
// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #9ed2ff; $primary-200:  #aad7ff; $primary-300:  #b5dcff; $primary-400:  #c0e1ff; $primary-500:  #cbe6ff; $primary-600:  #d6ebff;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #3b424c; $surface-mixed-200:  #4e555e; $surface-mixed-300:  #626870; $surface-mixed-400:  #777c83; $surface-mixed-500:  #8c9197; $surface-mixed-600:  #a2a6ab;
     
// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #6889a6; $primary-200:  #7995b0; $primary-300:  #89a2b9; $primary-400:  #9aafc3; $primary-500:  #aabccd; $primary-600:  #bbc9d7;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #31363d; $surface-mixed-200:  #454950; $surface-mixed-300:  #5a5e64; $surface-mixed-400:  #6f7378; $surface-mixed-500:  #86898d; $surface-mixed-600:  #9d9fa3;
                  
// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #bfb149; $primary-200:  #c7b95e; $primary-300:  #cfc272; $primary-400:  #d7ca86; $primary-500:  #ded39a; $primary-600:  #e5dbae;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #413c2e; $surface-mixed-200:  #544f42; $surface-mixed-300:  #676357; $surface-mixed-400:  #7c776d; $surface-mixed-500:  #908d84; $surface-mixed-600:  #a5a29b;
        
// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #736818; $primary-200:  #837733; $primary-300:  #93874b; $primary-400:  #a29764; $primary-500:  #b2a87c; $primary-600:  #c1b995;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #343026; $surface-mixed-200:  #48443a; $surface-mixed-300:  #5c5950; $surface-mixed-400:  #726e67; $surface-mixed-500:  #88857e; $surface-mixed-600:  #9e9c97;

// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #aaaaaa; $primary-200:  #b3b3b3; $primary-300:  #bcbcbc; $primary-400:  #c6c6c6; $primary-500:  #cfcfcf; $primary-600:  #d8d8d8;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #3c3b3e; $surface-mixed-200:  #4f4e51; $surface-mixed-300:  #636265; $surface-mixed-400:  #777779; $surface-mixed-500:  #8d8c8e; $surface-mixed-600:  #a3a2a4;
          
// /** SCSS DARK THEME PRIMARY COLORS */  $primary-100:  #04ff00; $primary-200:  #53ff3f; $primary-300:  #75ff5e; $primary-400:  #8fff78; $primary-500:  #a6ff90; $primary-600:  #baffa7;  /** SCSS DARK THEME SURFACE COLORS */  $surface-100:  #242326; $surface-200:  #38383a; $surface-300:  #4e4d50; $surface-400:  #656467; $surface-500:  #7d7c7e; $surface-600:  #959597;  /** SCSS DARK THEME MIXED SURFACE COLORS */  $surface-mixed-100:  #314a2c; $surface-mixed-200:  #465c40; $surface-mixed-300:  #5b6f56; $surface-mixed-400:  #70826c; $surface-mixed-500:  #879683; $surface-mixed-600:  #9eaa9a;


/** SCSS DARK THEME PRIMARY COLORS */
$primary-100: #b3a7b5;
$primary-200: #bbb0bd;
$primary-300: #c3bac5;
$primary-400: #ccc4cd;
$primary-500: #d4cdd5;
$primary-600: #ddd7de;
/** SCSS DARK THEME SURFACE COLORS */
$surface-100: #261829;
$surface-200: #3b2d3d;
$surface-300: #504453;
$surface-400: #675c69;
$surface-500: #7f7580;
$surface-600: #978f98;
/** SCSS DARK THEME MIXED SURFACE COLORS */
$surface-mixed-100: #322435;
$surface-mixed-200: #463949;
$surface-mixed-300: #5b4e5d;
$surface-mixed-400: #706572;
$surface-mixed-500: #877d88;
$surface-mixed-600: #9e969f;



$bright-text: #f3f3f3;
$dark-text: #212121;

$brightness: 120;

@keyframes l32-dark {
    25%,
    100% {
      text-shadow:
        0.03px -0.01px 0.01px $surface-mixed-100,
        0.02px 0.02px 0 $surface-mixed-200,
        -0.02px 0.02px 0 $surface-mixed-300;
    }
  }

#root {
    .rbt-menu.dropdown-menu {
        height: auto;
        max-height: 70vh !important;
    }

    & ~ .offcanvas {

        .offcanvas-body {
            display: flex;
            flex-direction: column;
        }

        .offcanvas-header {
            border-bottom: 1px solid $primary-100;
            color: $dark-text;
        }

        .offcanvas-footer {
            // align-self: flex-end;
            // justify-self: flex-end;
            margin-top: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            // align-items: flex-end;
            justify-content: flex-start;

            h6 {
                font-size: 16px;
                padding-left: 20px;

                &.smaller {
                    font-size: 12px;
                }
            }
        }

        a {
            color: $dark-text;
            border-bottom: 1px solid $primary-100;

            &:hover, &:focus {
                background: $surface-mixed-300;
                color: $bright-text;
            }
        }


        .offcanvas-body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;

            font-size: 20px;
        }


    }
}
#root.dark {
    transition: all 1s;
    background: $surface-mixed-100 url('../../public/bg-dark.png') no-repeat;
    background-size: cover;

    .rbt-input-hint {
        color: rgba(255,255,255, 0.5) !important;
    }

    & ~ .modal {
        input.form-control {
            background: $surface-mixed-100;
            color: $bright-text;
            border: 1px solid $surface-mixed-300;
            
            &::placeholder {
                color: $surface-mixed-600;
            }
        }
        
        .modal-content {
            background-color: $surface-mixed-200;
            color: $bright-text
        }

        .modal-header {
            border-bottom: 1px solid $surface-400;
        }

        .modal-footer {
            border-top: 1px solid $surface-400;
        }

        .btn-close {
            filter: invert(1);
        }
    }

    & ~ .offcanvas {
        background-color: $surface-mixed-100;

        
        .offcanvas-body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
        }

        .offcanvas-footer {
            h6 {
                color: $bright-text;
            }
        }

        .offcanvas-header {
            border-bottom: 1px solid $primary-100;
            color: $bright-text;
        }

        .btn-close {
            filter: invert(1);
        }

        a {
            color: $bright-text;
            border-bottom: 1px solid $primary-100;

            &:hover, &:focus {
                background: $surface-mixed-600;
                color: $dark-text;
            }
        }
    }

    input.form-control {
        background: $surface-mixed-100;
        color: $bright-text;
        border: 1px solid $surface-mixed-300;
        
        &::placeholder {
            color: $surface-mixed-600;
        }
    }

    .dropdown-item:hover, .dropdown-item:focus {
        background: $surface-mixed-300;
    }

    .btn-primary {
        background-color: $primary-100;
        border: 1px solid $surface-mixed-300;
        color: $dark-text;
        // font-weight: bold;
    }

    .btn-success {
        background-color: $primary-100;
        border: 1px solid $surface-mixed-300;
        color: $dark-text;
        // font-weight: bold;
    }


    .btn-info {
        background-color: $primary-100;
        border: 1px solid $surface-mixed-300;
        color: $dark-text;
        // font-weight: bold;
    }

    .loader {
        color: $bright-text;
        text-shadow: 0 0 0 $surface-mixed-100,
        0 0 0 $surface-mixed-200,
        0 0 0 $surface-mixed-300;
        animation: l32-dark 1s infinite cubic-bezier(0.5, -2000, 0.5, 2000);
    }

    .dropdown-menu {
        background: $surface-300;
        a {
            color: $bright-text;
            margin-left: 0;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 20px;
        }
    }

    .message-data-time {
        color: $bright-text;
    }
    .chat-about {
        color: $bright-text;
    }

    .chat-message {
        border-top: 2px solid $surface-mixed-300;
    }

    // .chat .chat-history .my-message {
    //     background: $surface-200;
    //     color: $bright-text;
    //     box-shadow: 0 0 20px 0px $surface-300;

    //     &::after {
    //         display: none;
    //     }
    // }

    .dropdown-toggle.btn-success {
        &::after {
            display: none;
        }
    }

    .chat .chat-history .other-message, .chat .chat-history .my-message {
        background: $surface-200;
        color: $bright-text;
        box-shadow: 0 0 20px 0px $surface-mixed-200;

        &::after {
            display: none;
        }
    }

    .chat .chat-header {
        // background: $surface-mixed-100;
        background: transparent;
        border-bottom: 2px solid $surface-mixed-300;
    }
    
    .card {
        // background: $surface-mixed-100;
        background: transparent;
    }

    .record-button {
        background-color: $primary-100;
        border: 1px solid $surface-300;
    }

    .scroll {
        background: transparent;
    }
}