.button-container {
    position: relative;

    .buttons {
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 100px;
        background: red;
    }
}
