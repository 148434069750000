body {
  background-color: #f4f7f6;
  /* margin-top:20px; */
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  border: 0;
}

body, html {
  height: 100%;
  width: 100%;
  border: 0;
  /* max-height: 100vh; */
  /* height: 100%; */
  /* overflow: hidden; */
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}


.text-right {
  text-align: right;
}

.card {
  background: #fff;
  transition: .5s;
  border: 0;
  margin-bottom: 30px;
  border-radius: .55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  margin-bottom: 0;
}

.chat-app {
  height: 100%;
}

.chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 7
}

.chat-app .chat {
  /* margin-left: 280px; */
  /* border-left: 1px solid #eaeaea; */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.record-button {
  background-color: #cccae5;
}

.chat .speak-icon {
  position: absolute;
  top: -90px;
  right: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.chat .speak-icon .btn {
  font-size: 30px;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 56;
  background: #ffffff;
  /* background: red; */
}

.chat .blocker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 55;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;;
}

.chat .blocker.visible {
  pointer-events: all;
  opacity: 1;
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px
}

.scroll {
  display: flex;
}

.row.clearfix .col-lg-12 {
  height: 100%;
}

.chat .chat-history {
  /* height: 100vh; */
  /* height: 100%; */
  padding: 20px;
  flex: 1;
  position: relative;
  z-index: 1;
  /* overflow-y: scroll; */
  /* height: 100%; */
}

.chat .chat-history .contact {
  position: fixed;
  top: 70px;
  left: 10px;
  padding: 10px;
  /* background: #cccccc;
  box-shadow: 0 0 20px rgba(0,0,0,0.2); */
}

.chat .chat-history >  ul {
  padding: 0
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 10px
}

/* .chat .chat-history p {
  list-style: none;
  margin-bottom: 10px
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px
} */

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history.text-right {
  display: flex;
  justify-content: flex-end;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
  box-shadow: 0 0 10px rgba(0,0,0,0.6);
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
  /* font-size: 18px; */
}

.chat .chat-history .message {
  color: #444;
  padding: 10px 12px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
  /* width: 80%; */
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px
}

/* .chat .chat-history .message>p:last-child {
  margin-bottom: 0;
} */

.chat .chat-history .my-message {
  background: #efefef
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: left;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  right: 10px;
  left: auto;
}

.chat .chat-message {
  padding: 20px;
  border-top: 2px solid #f4f7f6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: sticky;
  z-index: 2;
}

.chat .chat-message form {
  width: 100%;
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle
}

.online {
  color: #86c541
}

.offline {
  color: #e47297
}

.me {
  color: #1d8ecd
}

.float-right {
  float: right
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0
}
/* 
@media only screen and (max-width: 767px) {
  .chat-app .people-list {
    height: 465px;
    width: 100%;
    overflow-x: auto;
    background: #fff;
    left: -400px;
    display: none
  }

  .chat-app .people-list.open {
    left: 0
  }

  .chat-app .chat {
    margin: 0
  }

  .chat-app .chat-history {
    height: 300px;
    overflow-x: auto
  }
} */

/* @media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto
  }

  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto
  }

  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto
  }
} */


.login-screen {
  /* display: flex; */
  /* flex-direction: column; */
  background: #eeeeee url('../public/login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  /* flex: 1; */
  /* align-items: center; */
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.login-screen h1, .login-screen h2, .login-screen h3 {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.errors {
  color: rgb(182, 0, 0);
}

.form-signin {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.logo {
  border-radius: 24px;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  text-shadow:
    0 0 0 rgb(255 0 0),
    0 0 0 rgb(0 255 0),
    0 0 0 rgb(0 0 255);
  font-size: 30px;
  animation: l32 1s infinite cubic-bezier(0.5, -2000, 0.5, 2000);
}

@keyframes l32 {
  25%,
  100% {
    text-shadow:
      0.03px -0.01px 0.01px rgb(255 0 0),
      0.02px 0.02px 0 rgb(0 255 0),
      -0.02px 0.02px 0 rgb(0 0 255);
  }
}


.avatar-container {
  align-items: center;
  display: flex;
  flex: 1;
}

.dropup-container .dropdown-menu {
  max-height: 80vh;
  overflow-y: scroll;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.wrapper {
  height: 100%;
}

.row {
  height: 100%;
}

.wrapper>div {
  transition: opacity 0.2s;
}

.wrapper>div:not(.loader, .email-overlay, .side-menu) {
  opacity: 0;
}

.wrapper.wrapper--ready>.row.clearfix {
  opacity: 1;
}

.wrapper.wrapper--ready .loader {
  opacity: 0;
  pointer-events: none;
}
/* 
.search-container {
  position: relative;
} */

.search-inner {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  right: 10px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.scroll {
  flex: 1;
  overflow-y: scroll;
  background: url('../public/bg.png') no-repeat;
  background-size: cover;
  background-position: bottom center;
}
