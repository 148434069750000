.email-overlay {
    position: fixed;
    z-index: 66;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.68);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    &__form {
        background-color: #cccccc;
        border-radius: 10px;
        box-shadow: 0px 0px 20px rgba(0,0,0,0.2) inset;
        width: 80%;
        padding: 10px;
    }
}